import React, { Component } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Carousel from "react-bootstrap/Carousel";

class AlbumCard extends Component {
  state = {
    coverArt: [
      {
        image: "./no_disc.png",
        thumbnails: { small: "./no_disc.png" },
      },
    ],
    selectedArt: 0,
  };

  componentDidMount() {
    console.log("componentDidUpdate", this.props.album[0].id);
    // get cover art now and replace the art currently onscreen
    //console.log("Search for Artist ", this.state.inputString);
    const uriString =
      "https://coverartarchive.org/release/" + this.props.album[0].id;
    //"https://coverartarchive.org/release/062104f3-b228-4c21-ae80-5463bc6e3991
    fetch(uriString)
      .then((response) => {
        if (response.ok) {
          return response;
        } else {
          console.log("no response");
          throw new Error("no response");
        }
      })
      .then((data) => data.json())
      .then((data) => {
        console.log("Cover art images: ", data.images);
        this.setState({
          coverArt: data.images,
        });
        //console.log("First artists: ", data.artists[0]);
      })
      .catch(function (error) {
        console.log("Caught error ", error);
      });
  }

  render() {
    let album = this.props.album[0];
    console.log("Album details:", album);
    console.log("Artist: ", this.props.artist);
    return (
      <div className="card bg-success border-success text-white p-3">
        <Card.Text text="text-shadow: -1px -1px 1px #000, 1px 1px 1px #2ba6cb">
          <Row>
            <Col xs={6} md={4}>
              {/* <Image src={this.state.coverArt[0].image} fluid /> */}
              {this.showCarousel(this.state.coverArt)}
            </Col>
            <Col xs={6} md={4}>
              <ul>
                <li>Album title: {album.title}</li>
                <li>Release date: {album.date}</li>
                <li>Disambiguation: {album.disambiguation}</li>
                <li>Media QTY: {album.media.length}</li>
              </ul>
              {/* <p>
              {this.state.coverArt.map((art) => (
                <Image src={art.thumbnails.small} thumbnail fluid />
              ))}
            </p> */}
            </Col>
          </Row>
          <h3>Media in this release</h3>
          {this.showMedia(album)}
          <br />
        </Card.Text>
      </div>
    );
  }

  showCarousel = (coverArt) => {
    return (
      <Carousel>
        {coverArt.map((item) => (
          <Carousel.Item>
            <img className="d-block w-100" src={item.image} alt="Cover 1" />
          </Carousel.Item>
        ))}
      </Carousel>
    );
  };

  showMedia = (album) => {
    const cardBG = "secondary";
    const searchBase = "https://www.youtube.com/results?search_query=";

    return (
      <Accordion defaultActiveKey="0">
        {album.media.map((media, index) => (
          <Card bg={cardBG}>
            <Accordion.Toggle as={Card.Header} eventKey={"" + index}>
              <h3>
                {media.format + (media.title === "" ? "" : " - " + media.title)}
              </h3>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"" + index}>
              <Card.Body>
                <ul>
                  {media.tracks.map((track) => (
                    <li key={track.title + index}>
                      {track.title} - {this.getDuration(track.length)}-{" "}
                      <a
                        href={
                          searchBase +
                          encodeURIComponent(this.props.artist) +
                          "+" +
                          encodeURIComponent(track.title)
                        }
                      >
                        <Image src="yt_logo_rgb_dark.png" alt="YouTube" />
                      </a>
                    </li>
                  ))}
                </ul>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    );
  };

  getDuration = (miliseconds) => {
    let seconds = parseInt(Math.floor(miliseconds / 1000));
    let minutes = parseInt(Math.floor(seconds / 60));
    seconds = parseInt(seconds % 60);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  };
}

export default AlbumCard;
