import React, { Component } from "react";
import AlbumCard from "./AlbumCard";
//import Form from "react-bootstrap/Form";

class ArtistSearch extends Component {
  state = {
    inputString: "",
    selectedAlbum: -1,
    artistsFound: [
      //   {
      //     name: "Iron Maiden",
      //     id: "ca891d65-d9b0-4258-89f7-e6ba29d83767",
      //     score: "100",
      //     type: "Group",
      //   },
    ],
    albumsFound: [
      //   {
      //     id: "",
      //     title: "",
      //     date: "",
      //   },
    ],
    albumDetails: [],
  };

  inputChanged = (event) => {
    this.setState({ inputString: event.target.value });
    //console.log("Input Changed ", event.target.value);
  };

  keyPressed = (event) => {
    if (event.key === "Enter") {
      this.doSearchArtist();
    }
  };

  doSearchArtist = () => {
    //console.log("Search for Artist ", this.state.inputString);
    const uriString =
      "https://musicbrainz.org/ws/2/artist/?fmt=json&query=artist:" +
      encodeURIComponent(this.state.inputString.trim());
    //"http://musicbrainz.org/ws/2/artist/?fmt=json&query=artist:iron%20maiden"
    fetch(uriString)
      .then((response) => {
        if (response.ok) {
          return response;
        } else {
          console.log("no response");
          return;
        }
      })
      .then((data) => data.json())
      .then((data) => {
        //console.log(data);
        this.setState({
          artistsFound: data.artists,
          albumsFound: [],
          selectedAlbum: -1,
        });
        //console.log("First artists: ", data.artists[0]);
      });

    //this.setState({ artistsFound: [] }); // erase the list before searching
  };

  doSearchAlbum = (event) => {
    //console.log("Search Albums value ", event.target.value);
    //console.log("Search Albums id ", event.target.id);
    // https://musicbrainz.org/ws/2/artist/ca891d65-d9b0-4258-89f7-e6ba29d83767?fmt=json&inc=releases
    // const uriString =
    //   "https://musicbrainz.org/ws/2/artist/" +
    //   event.target.id +
    //   "?fmt=json&inc=releases&limit=100";
    const uriString =
      "https://musicbrainz.org/ws/2/release/?artist=" +
      event.target.id +
      "&fmt=json&limit=1000";

    //console.log("Request URI ", uriString);
    fetch(uriString)
      .then((response) => {
        if (response.ok) {
          return response;
        } else {
          console.log("no response");
          throw new Error("no response");
        }
      })
      .then((data) => data.json())
      .then((data) => {
        //console.log(data);
        const artist = [this.state.artistsFound[event.target.value]];
        this.setState({
          albumsFound: data["releases"],
          artistsFound: artist,
          selectedAlbum: -1,
        });
        console.log("Number of albums: ", this.state.albumsFound.length);
      })
      .catch(function (error) {
        console.log("Caught error ", error);
      });
  };

  doAlbumDetail = (event) => {
    console.log("Selected album ", event.target.id);
    const idx = event.target.id;

    //http://musicbrainz.org/ws/2/release/6a0fdf55-443f-4a47-9b51-c8c0dcf6b259?inc=recordings
    const uriString =
      "https://musicbrainz.org/ws/2/release/" +
      this.state.albumsFound[idx].id +
      "?fmt=json&inc=recordings&limit=100";
    fetch(uriString)
      .then((response) => {
        if (response.ok) {
          return response;
        } else {
          console.log("no response");
          throw new Error("no response");
        }
      })
      .then((data) => data.json())
      .then((data) => {
        console.log(data);
        const album = [this.state.albumsFound[idx]];
        const albumDetail = [data];
        this.setState({
          albumsFound: album,
          albumDetails: albumDetail,
          selectedAlbum: 0,
        });
        //console.log("First album: ", data.releases[0]);
      })
      .catch(function (error) {
        console.log("Caught error ", error);
      });

    // this.setState({
    //   albumsFound: album,
    //   selectedAlbum: 0,
    // });
  };

  getScoreString(score) {
    return score === 100 ? "Best Match" : score + "%";
  }
  //<div className="container-fluid text-center mx-auto p-3 mt-2">
  render() {
    return (
      <div className="card bg-dark border-dark text-white p-3">
        <blockquote className="blockquote text-right">
          <em>Enter artist name to search.</em>
        </blockquote>
        <div className="input-group input-group-lg">
          {/* <div className="input-group-prepend">
            <span className="input-group-text" id="inputGroup-sizing-lg">
              Artist Name
            </span>
          </div> */}

          <input
            type="text"
            className="form-control"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
            id="search-input"
            onChange={this.inputChanged}
            onKeyPress={this.keyPressed}
          />
          <div className="input-group-append">
            <button
              className="btn btn-warning"
              type="button"
              id="search-button"
              onClick={this.doSearchArtist}
            >
              Search
            </button>
          </div>
        </div>
        {this.state.artistsFound.map((artistsFound, index) =>
          this.getArtistButton(index)
        )}
        <div className="card bg-dark border-dark text-white">
          {this.state.albumsFound.map((albumsFound, index) =>
            this.getAlbumButton(albumsFound, index)
          )}
        </div>
      </div>
    );
  }

  getArtistButton = (artistIndex) => {
    const artist = this.state.artistsFound[artistIndex];
    //console.log("Adding artist index", artistIndex, " id ", artist.id);
    return (
      <button
        type="button"
        className="btn btn-primary m-2"
        key={artist.id}
        id={artist.id}
        value={artistIndex}
        onClick={this.doSearchAlbum}
      >
        {artist.name}
        {artist.disambiguation === "" ? (
          "  "
        ) : (
          <i> - {artist.disambiguation} </i>
        )}
        <span className="badge badge-dark">
          {this.getScoreString(artist.score)}
        </span>
      </button>
    );
  };

  getAlbumButton = (album, index) => {
    const selected = index == this.state.selectedAlbum ? true : false;
    // console.log(
    //   "getAlbumButton ",
    //   index,
    //   " ",
    //   this.state.selectedAlbum,
    //   selected
    // );
    let albumClass = "btn m-2 btn-";
    albumClass += selected ? "success" : "warning";
    return (
      <button
        type="button"
        className={albumClass}
        key={album.id}
        id={index}
        onClick={selected ? null : this.doAlbumDetail}
      >
        {album.title}
        {album.disambiguation === "" ? "  " : <i> - {album.disambiguation} </i>}
        <span className="badge badge-dark">{album.date}</span>
        {selected ? (
          <AlbumCard
            album={this.state.albumDetails}
            artist={this.state.artistsFound[0].name}
          />
        ) : null}
      </button>
    );
  };
}

export default ArtistSearch;
