import { Component } from "react";
import "./App.css";
import ArtistSearch from "./components/ArtistSearch";
import Badge from "react-bootstrap/Badge";
import Helmet from "react-helmet";

class App extends Component {
  state = {
    artist: "",
    artistID: "",
    album: "",
    albumID: "",
  };

  //<img src="Chaos_Warrior_Emblem.png" className="App-logo" alt="logo" />
  render() {
    return (
      <div className="App">
        <Helmet>
          <title>Eternal Requiem Music Finder</title>
          <meta
            name="description"
            content="Eternal Requiem is a search tool that allows you to easily find an Album, view some information plus cover art on it and then launch a search to listen to it."
          />

          <meta itemprop="name" content="Eternal Requiem Music Finder" />
          <meta
            itemprop="description"
            content="Eternal Requiem is a search tool that allows you to easily find an Album, view some information plus cover art on it and then launch a search to listen to it."
          />
          <meta
            itemprop="image"
            content="https://eternalreq.com/title_logo_rect.png"
          />

          <meta property="og:url" content="http://eternalreq.com" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Eternal Requiem Music Finder" />
          <meta
            property="og:description"
            content="Eternal Requiem is a search tool that allows you to easily find an Album, view some information plus cover art on it and then launch a search to listen to it."
          />
          <meta
            property="og:image"
            content="https://eternalreq.com/title_logo_rect.png"
          />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Eternal Requiem Music Finder" />
          <meta
            name="twitter:description"
            content="Eternal Requiem is a search tool that allows you to easily find an Album, view some information plus cover art on it and then launch a search to listen to it."
          />
          <meta
            name="twitter:image"
            content="https://eternalreq.com/title_logo_rect.png"
          />
        </Helmet>
        <img src="title_logo.png" alt="Eternal Requiem" className="img-fluid" />
        <ArtistSearch />
        <Badge pill variant="dark">
          Eternal Requiem Album search copyright(c) 2021 Daniel Burke, using
          data provided by <a href="https://musicbrainz.org">Music Brainz</a>{" "}
          <br />
          <a href="https://buymeacoffee.com/Burkey">
            <img src="BMC_small.png" alt="Buy me a Coffee" />
          </a>
        </Badge>
      </div>
    );
  }
}

export default App;
